<template>
  <div class="news-list" v-loading="pageLoading">
    <div class="company">
      <div class="company-data flex-row">
        <div class="flex-cell flex-col">
          <div class="names">{{contData.basic_com_name}}</div>
          <div class="flex-cell"><!-- 占位符 --></div>
          <div class="other">
            <i class="iconfont iconbanquan m-r-10"></i
            >统一社会信用代码：{{contData.basic_uscc_id_card}}
          </div>
          <div class="other">
            <i class="iconfont icondizhi m-r-10"></i
            >注册地址：{{contData.basic_reg_address}}
          </div>
        </div>
        <div class="thumb txt-center">
          <img class="" :src="contData.basic_logo" alt="" />
        </div>
      </div>
      <div class="company-cont">
        <div class="company-tab">
          <div
            class="cursor-pointer"
            :class="index == idx ? 'sel_tab' : ''"
            v-for="(item, index) in arr"
            :key="index"
            @click="doChangeTab(index)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="company-table">
          <div class="" v-show="idx == 0">
            <el-descriptions class="margin-top" :column="1"  border>
              <el-descriptions-item label="归属地区" label-class-name="custor-label">{{contData.expand_data_area || '无'}}</el-descriptions-item>
              <el-descriptions-item label="统一社会信用代码" label-class-name="custor-label">{{contData.basic_uscc_id_card || '无'}}</el-descriptions-item>
              <el-descriptions-item label="公司名称" label-class-name="custor-label">{{contData.basic_com_name || '无'}}</el-descriptions-item>
              <el-descriptions-item label="企业类型" label-class-name="custor-label">{{contData.basic_com_type || '无'}}</el-descriptions-item>
              <el-descriptions-item label="经营范围" label-class-name="custor-label">{{contData.basic_serv_area || '无'}}</el-descriptions-item>
              <el-descriptions-item label="注册资金	" label-class-name="custor-label">{{contData.basic_reg_amount || '无'}}</el-descriptions-item>
              <el-descriptions-item label="注册日期" label-class-name="custor-label">{{contData.establish_date || '无'}}</el-descriptions-item>
              <el-descriptions-item label="注册地址" label-class-name="custor-label">{{contData.basic_reg_address || '无'}}</el-descriptions-item>
              <el-descriptions-item label="经营起始日期" label-class-name="custor-label">{{contData.basic_sell_start_date || '无'}}</el-descriptions-item>
              <el-descriptions-item label="经营截止日期" label-class-name="custor-label">{{contData.basic_sell_end_date || '无'}}</el-descriptions-item>
              <el-descriptions-item label="法人姓名" label-class-name="custor-label">{{contData.basic_ceo_name || '无'}}</el-descriptions-item>
              <el-descriptions-item label="法人身份证号	" label-class-name="custor-label">{{contData.basic_ceo_id_card || '无'}}</el-descriptions-item>
              <el-descriptions-item label="法人联系方式" label-class-name="custor-label">{{contData.basic_ceo_phone || '无'}}</el-descriptions-item>
              <el-descriptions-item label="企业信用等级信息	" label-class-name="custor-label">{{contData.basic_credit_lvl || '无'}}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="" v-show="idx == 1">
            <el-descriptions class="margin-top" :column="1"  border>
              <el-descriptions-item label="行政处罚" label-class-name="custor-label">{{contData.basic_punish || '无'}}</el-descriptions-item>
              <el-descriptions-item label="处罚事由" label-class-name="custor-label">{{contData.basic_punish_desc || '无'}}</el-descriptions-item>
              <el-descriptions-item label="行政处罚文书号" label-class-name="custor-label">{{contData.basic_punish_num || '无'}}</el-descriptions-item>
              <el-descriptions-item label="处罚依据" label-class-name="custor-label">{{contData.basic_punish_reason || '无'}}</el-descriptions-item>
              <el-descriptions-item label="处罚结果" label-class-name="custor-label">{{contData.basic_punish_res || '无'}}</el-descriptions-item>
              <el-descriptions-item label="处罚决定日期	" label-class-name="custor-label">{{contData.basic_punish_time || '无'}}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="" v-show="idx == 2">
            <el-descriptions class="margin-top" :column="1"  border>
              <el-descriptions-item label="受表彰情况" label-class-name="custor-label">{{contData.basic_honour || '无'}}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="" v-show="idx == 3">
            <el-descriptions class="margin-top" :column="1"  border>
              <el-descriptions-item label="列入认定机关" label-class-name="custor-label">{{redInfo.add_decide_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入文号" label-class-name="custor-label">{{redInfo.add_doc_num || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入事由" label-class-name="custor-label">{{redInfo.add_reason || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入日期" label-class-name="custor-label">{{redInfo.add_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="法定代表人姓名" label-class-name="custor-label">{{redInfo.ceo_name || '无'}}</el-descriptions-item>
              <el-descriptions-item label="执行反馈时间" label-class-name="custor-label">{{redInfo.feedback_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="奖励部门" label-class-name="custor-label">{{redInfo.honnor_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="奖励措施" label-class-name="custor-label">{{redInfo.honnor_desc || '无'}}</el-descriptions-item>
              <el-descriptions-item label="法定代表人身份证件号码" label-class-name="custor-label">{{redInfo.id_card || '无'}}</el-descriptions-item>
              <el-descriptions-item label="守信领域" label-class-name="custor-label">{{redInfo.keep_area || '无'}}</el-descriptions-item>
              <el-descriptions-item label="主体名称" label-class-name="custor-label">{{redInfo.name || '无'}}</el-descriptions-item>
              <el-descriptions-item label="组织机构代码" label-class-name="custor-label">{{redInfo.org_code || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出决定机关" label-class-name="custor-label">{{redInfo.out_decide_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出原因" label-class-name="custor-label">{{redInfo.out_reason || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出日期" label-class-name="custor-label">{{redInfo.out_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="公示期限" label-class-name="custor-label">{{redInfo.pub_lmt || '无'}}</el-descriptions-item>
              <el-descriptions-item label="登记证号" label-class-name="custor-label">{{redInfo.reg_card || '无'}}</el-descriptions-item>
              <el-descriptions-item label="统一社会信用代码或身份证号码" label-class-name="custor-label">{{redInfo.uscc_or_id_card || '无'}}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="" v-show="idx == 4">
            <el-descriptions class="margin-top" :column="1"  border>
              <el-descriptions-item label="列入认定机关" label-class-name="custor-label">{{blackInfo.add_decide_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入文号" label-class-name="custor-label">{{blackInfo.add_doc_num || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入事由" label-class-name="custor-label">{{blackInfo.add_reason || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入日期" label-class-name="custor-label">{{blackInfo.add_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="法定代表人姓名" label-class-name="custor-label">{{blackInfo.ceo_name || '无'}}</el-descriptions-item>
              <el-descriptions-item label="执行反馈时间" label-class-name="custor-label">{{blackInfo.feedback_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="奖励部门" label-class-name="custor-label">{{blackInfo.honnor_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="奖励措施" label-class-name="custor-label">{{blackInfo.honnor_desc || '无'}}</el-descriptions-item>
              <el-descriptions-item label="法定代表人身份证件号码" label-class-name="custor-label">{{blackInfo.id_card || '无'}}</el-descriptions-item>
              <el-descriptions-item label="守信领域" label-class-name="custor-label">{{blackInfo.keep_area || '无'}}</el-descriptions-item>
              <el-descriptions-item label="主体名称" label-class-name="custor-label">{{blackInfo.name || '无'}}</el-descriptions-item>
              <el-descriptions-item label="组织机构代码" label-class-name="custor-label">{{blackInfo.org_code || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出决定机关" label-class-name="custor-label">{{blackInfo.out_decide_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出原因" label-class-name="custor-label">{{blackInfo.out_reason || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出日期" label-class-name="custor-label">{{blackInfo.out_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="公示期限" label-class-name="custor-label">{{blackInfo.pub_lmt || '无'}}</el-descriptions-item>
              <el-descriptions-item label="登记证号" label-class-name="custor-label">{{blackInfo.reg_card || '无'}}</el-descriptions-item>
              <el-descriptions-item label="统一社会信用代码或身份证号码" label-class-name="custor-label">{{blackInfo.uscc_or_id_card || '无'}}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="" v-show="idx == 5">
            <el-descriptions class="margin-top" :column="1"  border>
              <el-descriptions-item label="列入认定机关" label-class-name="custor-label">{{grayInfo.add_decide_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入文号" label-class-name="custor-label">{{grayInfo.add_doc_num || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入事由" label-class-name="custor-label">{{grayInfo.add_reason || '无'}}</el-descriptions-item>
              <el-descriptions-item label="列入日期" label-class-name="custor-label">{{grayInfo.add_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="法定代表人姓名" label-class-name="custor-label">{{grayInfo.ceo_name || '无'}}</el-descriptions-item>
              <!-- <el-descriptions-item label="执行反馈时间" label-class-name="custor-label">{{grayInfo.feedback_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="奖励部门" label-class-name="custor-label">{{grayInfo.honnor_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="奖励措施" label-class-name="custor-label">{{grayInfo.honnor_desc || '无'}}</el-descriptions-item> -->
              <el-descriptions-item label="法定代表人身份证件号码" label-class-name="custor-label">{{grayInfo.id_card || '无'}}</el-descriptions-item>
              <el-descriptions-item label="守信领域" label-class-name="custor-label">{{grayInfo.keep_area || '无'}}</el-descriptions-item>
              <el-descriptions-item label="主体名称" label-class-name="custor-label">{{grayInfo.name || '无'}}</el-descriptions-item>
              <el-descriptions-item label="组织机构代码" label-class-name="custor-label">{{grayInfo.org_code || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出决定机关" label-class-name="custor-label">{{grayInfo.out_decide_by || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出原因" label-class-name="custor-label">{{grayInfo.out_reason || '无'}}</el-descriptions-item>
              <el-descriptions-item label="移出日期" label-class-name="custor-label">{{grayInfo.out_time || '无'}}</el-descriptions-item>
              <el-descriptions-item label="公示期限" label-class-name="custor-label">{{grayInfo.pub_lmt || '无'}}</el-descriptions-item>
              <el-descriptions-item label="登记证号" label-class-name="custor-label">{{grayInfo.reg_card || '无'}}</el-descriptions-item>
              <el-descriptions-item label="统一社会信用代码或身份证号码" label-class-name="custor-label">{{grayInfo.uscc_or_id_card || '无'}}</el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
export default {
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      arr: [
        {
          label: "基本信息",
          val: 0,
        },
        {
          label: "行政处罚",
          val: 1,
        },
        {
          label: "推树活动",
          val: 2,
        },
        {
          label: "红名单",
          val: 3,
        },
        {
          label: "黑名单",
          val: 4,
        },
        {
          label: "灰名单",
          val: 5,
        },
      ],
      idx: 0,
      contData: {},
      blackInfo: {},
      grayInfo: {},
      redInfo: {},
    };
  },
  mounted() {
    // 加载数据
    l_loadData(this)
  },
  methods: {
    // 切换选项卡
    doChangeTab(idx) {
      this.idx = idx;
    },
  },
};

// 获取数据
function l_loadData(pg){
  pg.pageLoading = true
  requestGet("api/company/detail/"+pg.$route.params.id, {})
    .then((res) => {
      pg.contData = res.data.baseInfo || {}
      pg.blackInfo = res.data.blackInfo || {}
      pg.grayInfo = res.data.grayInfo || {}
      pg.redInfo = res.data.redInfo || {}
      pg.pageLoading = false
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false
    });
}

</script>
<style lang="scss" scoped>
.news-list {
  width: 1200px;
  margin: 0 auto;
  .company {
    background: #fff;
    padding: 20px;
    .company-data {
      background: #fafafa;
      border: 1px solid #e8e8e8;
      padding: 5px 20px;
      .names {
        font-size: 35px;
      }
      .other {
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 10px;
        
        .iconfont {
          color: #db3e3e;
        }
      }
      .thumb {
        width: 175px;
        height: 175px;
        background: #fff;
        img{
          width: 90%;
          max-height: 90%;
        }
      }
    }
    .company-cont {
      font-size: 14px;
      .company-tab {
        border-bottom: 1px solid #db3e3e;
        margin: 20px 0;
        div {
          display: inline-block;
          padding: 10px 20px;
        }
        .sel_tab {
          background: #db3e3e;
          color: #fff;
        }
      }
      .company-table{
          ::v-deep .custor-label{
            width: 150px!important;
            color: #333!important;
            text-align: right;
        }
      }
    }
  }
}
</style>
